import "./Aboutme.css";
import Zoom from "react-reveal/Zoom";
import logo from "../images/logo.png";
import aboutmeImg from "./aboutme.svg";
import { Slide } from "react-awesome-reveal";
import { Fade } from "react-awesome-reveal";
import img1 from "./corporate.jpg";
import img2 from "./events.jpg";
import img3 from "./influencer.jpg";
import img4 from "./product.jpg";
import img5 from "./4.jpg";
import img6 from "./5.jpg";
import img7 from "./DCP_9903.jpg";
const Aboutme = () => {
  return (
    <>
      <div className="about-me-main">
        <div className="about-image">
          <img src={img7} alt="" />
        </div>
        {/* <Slide> */}
        <Fade duration={2000}>
          <div className="about-me-landing">
            <span>India Based Cinematographer</span>
            <h3>Crossfade Films</h3>
            <p>
              <p style={{ textTransform: "uppercase" }}>
                We flow with your moments
              </p>
              Team Crossfade Films flow with your moments and your search for
              all your videography/photography services ends here.
            </p>
            <a href="/contact">
              <button>GET IN TOUCH</button>
            </a>
          </div>
        </Fade>
        <div className="about-me-div">
          <h1 className="about-me-text">ABOUT ME</h1>
        </div>
        {/* </Slide> */}
      </div>
      {/* <h1
        style={{
          textAlign: "center",
          marginTop: "30px",
        }}
      >
        <marquee direction="right">CrossFade Films Presents</marquee>
      </h1> */}

      <div class="example1">
        <h3 style={{ fontSize: "3rem", textTransform: "uppercase" }}>
          CrossFade Films Presents
        </h3>
      </div>
      <div className="video-gallery-both">
        <div className="videography-head-bar">
          <p>VideoGallery</p>
          <a href="/videoshoot">
            <button className="see-all-button">See All</button>
          </a>
        </div>
        <div className="videogallery-images">
          <a href="/videoshoot">
            <img src={img1} alt="" />
          </a>
          <a href="/videoshoot">
            <img src={img2} alt="" />
          </a>

          <a href="/videoshoot">
            <img src={img3} alt="" />
          </a>
        </div>
        <br />
        <div className="videography-head-bar">
          <p>PhotoGallery</p>
          <a href="/Portfolio">
            <button className="see-all-button">See All</button>
          </a>
        </div>
        <div className="videogallery-images">
          <a href="/Portfolio">
            <img src={img4} alt="" />
          </a>
          <a href="/Portfolio">
            <img src={img5} alt="" />
          </a>
          <a href="/Portfolio">
            <img src={img6} alt="" />
          </a>
        </div>
      </div>
    </>
  );
};
export default Aboutme;
