import "./Header.css";
import "boxicons";
import Portfolio from "../Portfolio/Portfolio";
import logo from "../images/logo.png";
import { useState } from "react";
const Header = () => {
  const [isMobile, setMobile] = useState(false);
  return (
    <div className="header-container">
      <div className="wrapper">
        <div>
          <a href="/">
            <img src={logo} />
            {/* <h1
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              CROSSFADE LOGO
            </h1> */}
          </a>
        </div>
        <ul>
          <li
            className={isMobile ? "header-mobile-links" : "header-links"}
            onClick={() => {
              setMobile(false);
            }}
          >
            <a href="aboutme">About Us</a>
            <a href="videoshoot">VideoGallery</a>
            <a href="portfolio">PhotoGallery</a>
            {/* <a href="featured">Featured</a> */}
            {/* <a href="programs">Programs</a> */}
            <a href="contact">Contact</a>
          </li>
        </ul>
        <button
          className="mobile-menu-icon"
          onClick={() => {
            setMobile(!isMobile);
          }}
        >
          {isMobile ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </button>
      </div>
    </div>
  );
};
export default Header;
