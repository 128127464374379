import "./Corporate.css";
import Fade from "react-reveal/Fade";
import img1 from "./1.jpg";
const Corporate = () => {
  const videoShown = [
    {
      id: 1,
      videoSrc: "https://www.youtube.com/embed/WopqN6vO73o",
    },
    {
      id: 2,
      videoSrc: "https://www.youtube.com/embed/3deqdiQ08fA",
    },

    {
      id: 3,
      videoSrc: "https://www.youtube.com/embed/2ZT9IM0ehzs",
    },
    {
      id: 4,
      videoSrc: "https://www.youtube.com/embed/hN9AqgkCoIU",
    },
    {
      id: 5,
      videoSrc: "https://www.youtube.com/embed/OrPc_nLl4fE",
    },
    {
      id: 6,
      videoSrc: "https://www.youtube.com/embed/ffwkrLFHkgw",
    },
    {
      id: 7,
      videoSrc: "https://www.youtube.com/embed/mGuAR15Aveo",
    },
  ];
  return (
    <>
      <div>
        <img className="product-image" src={img1} alt="" />
        <Fade up duration="2000">
          <h1 className="images-shoot-corporate">CORPORATE SHOOT</h1>
        </Fade>
        <a
          style={{
            position: "absolute",
            zIndex: "1",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          href="/contact"
        >
          <Fade duration="4000">
            <button
              className="button-get-in-cat"
              style={{ padding: "10px 20px" }}
            >
              GET IN TOUCH
            </button>
          </Fade>
        </a>
      </div>
      <div style={{ columnCount: "2" }} className="video-shoot-container">
        {videoShown.map((videoelement) => {
          return (
            <div className="video-shoot-item" key={videoelement.id}>
              <iframe
                src={videoelement.videoSrc}
                // frameborder="0"
                style={{ margin: "8px 0" }}
                width="100%"
                height="250px"
                allow="autoplay; encrypted-media"
                allowfullscreen
                // title="video"
              />{" "}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Corporate;
