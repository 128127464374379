import "./Contact.css";
const Contact = () => {
  return (
    <>
      <div>
        <section id="contact">
          <div class="contact-box">
            <div class="contact-links">
              <h2 className="contact-me contact-me-head">CONTACT</h2>
              <br />
              <h2 className="contact-me">ME</h2>
              {/* <div class="links">
              <div class="link">
                <a>
                  <img
                    className="contact-img"
                    src="https://i.postimg.cc/m2mg2Hjm/linkedin.png"
                    alt="linkedin"
                  />
                </a>
              </div>
              <div class="link">
                <a>
                  <img
                    className="contact-img"
                    src="https://i.postimg.cc/YCV2QBJg/github.png"
                    alt="github"
                  />
                </a>
              </div>
              <div class="link">
                <a>
                  <img
                    className="contact-img"
                    src="https://i.postimg.cc/W4Znvrry/codepen.png"
                    alt="codepen"
                  />
                </a>
              </div>
              <div class="link">
                <a>
                  <img
                    className="contact-img"
                    src="https://i.postimg.cc/NjLfyjPB/email.png"
                    alt="email"
                  />
                </a>
              </div>
            </div> */}
            </div>
            <div class="contact-form-wrapper">
              <form>
                <div class="form-item">
                  {/* <input type="text" name="sender" required /> */}

                  <h4>PHONE NUMBER:</h4>
                  <a
                    href="tel:5551234567"
                    style={{ textDecoration: "none", color: "#333" }}
                  >
                    +91 8130226257
                  </a>
                </div>

                <br />
                <div class="form-item">
                  <h4>Email ID:</h4>
                  <a
                    style={{ textDecoration: "none", color: "#333" }}
                    href="mailto: crossfadefilmsofficial@gmail.com"
                  >
                    crossfadefilmsofficial@gmail.com
                  </a>
                  {/* <input type="text" name="email" required /> */}
                  {/* <label>Email:</label> */}
                </div>
                <br />
                <div class="form-item">
                  {/* <input type="text" name="sender" required /> */}

                  <h4>Instagram:</h4>
                  <a
                    href="https://www.instagram.com/crossfade_films/"
                    style={{ textDecoration: "none", color: "#333" }}
                  >
                    @crossfade_films
                  </a>
                </div>
                <br />
                <div class="form-item">
                  {<textarea class="" name="message" required></textarea>}
                  {<label>Message:</label>}
                </div>
                <a style={{ textDecoration: "none" }} href="/">
                  <button class="submit-btn-1">Have a nice day!</button>
                </a>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Contact;
