import { useState } from "react";
import "./MoreAboutMe.css";
import "../PortfolioPage/PortfolioPage.css";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import img1 from "./DCP_9903.jpg";

import "./MoreAboutMe.css";
const MoreAboutMe = () => {
  // let data = [
  //   {
  //     id: 1,
  //     imgSrc: img1,
  //     category: "influencer",
  //   },
  //   {
  //     id: 2,
  //     imgSrc: img2,
  //     category: "corporate",
  //   },
  //   {
  //     id: 3,
  //     imgSrc: img3,
  //     category: "productshoot",
  //   },
  //   {
  //     id: 4,
  //     imgSrc: img4,
  //     category: "influencer",
  //   },
  //   {
  //     id: 5,
  //     imgSrc: img5,
  //     category: "corporate",
  //   },
  //   {
  //     id: 6,
  //     imgSrc: img6,
  //     category: "productshoot",
  //   },
  // ];
  // const [model, setModel] = useState(false);
  // const [imgLink, setImgLink] = useState("");
  // const [sortedData, setSortedData] = useState(data);
  // const getImg = (imgSrc) => {
  //   console.log("hi");
  //   setImgLink(imgSrc);
  //   setModel(true);
  // };
  return (
    <div>
      {/* <div style={{ position: "relative" }} className="more-about-img">
        <img
          style={{
            width: "100%",
            height: "95vh",
            objectFit: "cover",
          }}
          src="https://images.pexels.com/photos/3992549/pexels-photo-3992549.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <h1>MORE ABOUT ME</h1>
      </div> */}
      {/* <div
        style={{
          marginTop: "100px",
        }}
        className="about-me-section"
      >
        <div className="about-me-container"> */}
      {/* <Fade left>
            <h1
              style={{
                fontSize: "3rem",
              }}
            >
              Hey 👋 I am your_name{" "}
              <span style={{ fontSize: "4.5rem" }}>. . . </span>
            </h1>
          </Fade> */}
      {/* <Fade left duration={3000}>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
              dolor dolores, quam dignissimos praesentium exercitationem in
              molestias porro blanditiis consectetur, consequatur tempore fuga
              quaerat officiis vitae, possimus similique nam quisquam! Mollitia
              voluptates fugit autem laudantium itaque ducimus aut nam, eum
              soluta distinctio voluptatum sapiente tenetur debitis velit veniam
              delectus odit omnis ratione cum similique? Porro at iure enim
              neque harum!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum quae
              asperiores tempore, impedit dignissimos deleniti ea eius nihil
              modi. Officiis eveniet officia sed saepe illo numquam suscipit
              sequi totam itaque.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
              dolor dolores, quam dignissimos praesentium exercitationem in
              molestias porro blanditiis consectetur, consequatur tempore fuga
              quaerat officiis vitae, possimus similique nam quisquam!
            </p>
          </Fade> */}
      {/* <Fade left duration={3500}>
            <a href="contact">
              <button style={{ margin: "30px 0" }} className="more-about">
                SHOOT WITH ME
              </button>
            </a>
          </Fade> */}
      {/* </div> */}

      {/* <div className="about-section-image">
        <Fade right duration={2000}>
          <img src="https://st2.depositphotos.com/1032577/5312/i/950/depositphotos_53128333-stock-photo-about-me-sign.jpg" />
        </Fade>
      </div> */}
      {/* </div> */}
      <div className="about-me-main">
        <div className="about-image">
          <img src={img1} alt="" />
        </div>
        {/* <Slide> */}
        <Fade duration={2000}>
          <div className="about-me about-me-more">
            <span>India Based Cinematographer</span>
            <h3>Crossfade Films</h3>
            <p>
              We flow with your moments
              <br />
              <br />
              Team Crossfade Films flow with your moments and your search for
              all your videography/photography services ends here.
            </p>
            <a href="/contact">
              <button>GET IN TOUCH</button>
            </a>
          </div>
        </Fade>
        {/* </Slide> */}
      </div>
      <br />
      <br />
      {/* <div className={model ? "model open" : "model"}>
        <img src={imgLink} />
        <i
          onClick={() => {
            setModel(false);
          }}
          class="fa-solid fa-xmark"
        ></i>
      </div> */}
      {/* <div
        style={{ marginBottom: "100px", marginTop: "50px" }}
        className="portfolio-page"
      >
        {sortedData.map((item) => {
          return (
            <div
              key={item.id}
              className="portfolio-page__item"
              onClick={() => {
                getImg(item.imgSrc);
              }}
            >
              <img
                style={{ width: "100%" }}
                // key={item.id}
                src={item.imgSrc}
                alt=""
              />
            </div>
          );
        })}
      </div> */}
    </div>
  );
};
export default MoreAboutMe;
