import "./Animated.css";
// import "https://crossfadefilm.com/urban-rashi.webm" from "../VideoShoot/"https://crossfadefilm.com/urban-rashi.webm".mp4";
import Fade from "react-reveal/Fade";
const Animated = () => {
  const videoShown = [
    {
      id: 1,
      videoSrc: "https://crossfadefilm.com/urban-rashi.webm",
    },
    {
      id: 2,
      videoSrc: "https://crossfadefilm.com/thermometer-full-res.webm",
    },

    {
      id: 3,
      videoSrc: "https://crossfadefilm.com/urban-rashi.webm",
    },
    {
      id: 4,
      videoSrc: "https://crossfadefilm.com/urban-rashi.webm",
    },
    {
      id: 5,
      videoSrc: "https://crossfadefilm.com/urban-rashi.webm",
    },
    {
      id: 6,
      videoSrc: "https://crossfadefilm.com/urban-rashi.webm",
    },
    {
      id: 7,
      videoSrc: "https://crossfadefilm.com/urban-rashi.webm",
    },
  ];
  return (
    <>
      <div>
        <img
          className="product-image"
          src="https://images.pexels.com/photos/3473569/pexels-photo-3473569.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt=""
        />
        <Fade up duration="2000">
          <h1 className="images-shoot">ANIMATED SHOOTS</h1>
        </Fade>
      </div>
      <div style={{ marginTop: "-100px", zIndex: 100 }}>
        <div className="video-shoot-container">
          {videoShown.map((videoelement) => {
            return (
              <div className="video-shoot-item" key={videoelement.id}>
                <video
                  className="video-shoot-iframe"
                  src={videoelement.videoSrc}
                  controls
                  loop
                  allowFullScreen
                ></video>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Animated;
