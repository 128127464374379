import logo from "./logo.svg";
import "./App.css";
import Video from "./components/Video/Video";
import Header from "./components/Header/Header";
import Aboutme from "./components/Aboutme/Aboutme";
// import Portfolio from "./components/Portfolio/Portfolio";
import Footer from "./components/Footer/Footer";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PortfolioPage from "./components/PortfolioPage/PortfolioPage";
import MoreAboutMe from "./components/MoreAboutMe/MoreAboutMe";
import Contact from "./components/Contact/Contact";
import Testimonial from "./components/Testimonial/Testimonial";
import ImgFooter from "./components/ImgFooter/ImgFooter";
// import VideoShoot from "./components/VideoShoot/VideoShoot";
import Category from "./components/Category/Category";
import ProductPage from "./components/ProductPage/ProductPage";
import Clients from "./components/ClientsServed/Clients";
import LifestylePage from "./components/LifestylePage/Lifestyle";
import Influencer from "./components/Influencer/Influencer";
import Event from "./components/EventShoot/Event";
import Corporate from "./components/Corporate/Corporate";
import Animated from "./components/Animated/Animated";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                <Video />
                <Aboutme />
                {/* <Portfolio /> */}
                <Testimonial />
                {/* <Clients /> */}
              </React.Fragment>
            }
          />
          <Route path="aboutme" element={<MoreAboutMe />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="contact" element={<Contact />} />

          <Route path="videoshoot" element={<Category />} />
          <Route path="productpage" element={<ProductPage />} />
          <Route path="lifestyle" element={<LifestylePage />} />
          <Route path="influencer" element={<Influencer />} />
          <Route path="event" element={<Event />} />
          <Route path="corporate" element={<Corporate />} />
          <Route path="animated" element={<Animated />} />
        </Routes>
        <Footer />
        <ImgFooter />
      </Router>
    </>
  );
}

export default App;
