import React from "react";
import styled from "styled-components";
import Slide from "react-reveal/Slide";
import ProductPage from "../ProductPage/ProductPage";
import Fade from "react-reveal/Fade";

import "./Category.css";

const Category = () => {
  return (
    <>
      <div style={{ marginTop: "-7px" }} className="category-div">
        {/* <img
          className="bg-image"
          src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/82873d27647351.58e09163aa88d.jpg"
        ></img> */}
        <video
          class="category-div-vid"
          muted="true"
          loop="true"
          autoplay="autoplay"
          width="100%"
        >
          <source
            src="https://crossfadefilm.com/lifestyle.webm"
            type="video/mp4"
          />
        </video>
        <Fade className="shoot-name" up duration="3000">
          <h1 style={{ fontFamily: "Lato", letterSpacing: "2px" }}>
            LIFESTYLE AND FASHION
          </h1>
        </Fade>

        <p className="shoot-tag-line">
          Best Fashion Shoots with Affordable Price
        </p>
        <Fade up duration="3000">
          <div className="two-buttons">
            <button className="shoot-button">
              <a href="/contact">Lets Shoot</a>
            </button>
            <button className="button-existing-inventory">
              <a href="lifestyle">Our Work</a>
            </button>
          </div>
        </Fade>
      </div>
      <div style={{ marginTop: "-7px" }} className="category-div">
        {/* <img
          className="bg-image"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
        ></img> */}

        <video
          class="category-div-vid"
          muted="true"
          loop="true"
          autoplay="autoplay"
          style={{ width: "100%" }}
        >
          <source
            src="https://crossfadefilm.com/influencer.webm"
            type="video/mp4"
          />
        </video>
        <Fade up duration="3000">
          <h1
            className="shoot-name"
            s
            tyle={{
              fontFamily: "Lato",
              letterSpacing: "2px",
            }}
          >
            INFLUENCER SHOOT
          </h1>
        </Fade>

        <p className="shoot-tag-line">
          Best Influencer Shoots with Affordable Price
        </p>
        <Fade up duration="3000">
          <div className="two-buttons">
            <button className="shoot-button">
              <a href="/contact">Lets Shoot</a>
            </button>
            <button className="button-existing-inventory">
              <a href="influencer">Our Work</a>
            </button>
          </div>
        </Fade>
      </div>
      <div style={{ marginTop: "-7px" }} className="category-div">
        {/* <img
          className="bg-image"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
        ></img> */}
        <video
          class="category-div-vid"
          muted="true"
          autoplay="autoplay"
          loop="true"
          width="100%"
        >
          <source
            src="https://crossfadefilm.com/events.webm"
            type="video/mp4"
          />
        </video>
        <Fade className="shoot-name" up duration="3000">
          <h1 style={{ fontFamily: "Lato", letterSpacing: "2px" }}>
            EVENT SHOOT
          </h1>
        </Fade>

        <p className="shoot-tag-line">
          Best Event Shoots with Affordable Price
        </p>
        <Fade up duration="3000">
          <div className="two-buttons">
            <button className="shoot-button">
              <a href="/contact">Lets Shoot</a>
            </button>

            <button className="button-existing-inventory">
              <a href="event">Our Work</a>
            </button>
          </div>
        </Fade>
      </div>
      <div style={{ marginTop: "-7px" }} className="category-div">
        {/* <img
          className="bg-image"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
        ></img> */}
        <video
          class="category-div-vid"
          muted="true"
          loop="true"
          autoplay="autoplay"
        >
          <source
            src="https://crossfadefilm.com/corporate.webm"
            type="video/mp4"
          />
        </video>
        <Fade className="shoot-name" up duration="3000">
          <h1 style={{ fontFamily: "Lato", letterSpacing: "2px" }}>
            CORPORATE SHOOT
          </h1>
        </Fade>

        <p className="shoot-tag-line">
          Best Corporate Shoots with Affordable Price
        </p>
        <Fade up duration="3000">
          <div className="two-buttons">
            <button className="shoot-button">
              <a href="/contact">Lets Shoot</a>
            </button>
            <button className="button-existing-inventory">
              <a href="corporate">Our Work</a>
            </button>
          </div>
        </Fade>
      </div>
      <div style={{ marginTop: "-7px" }} className="category-div">
        <video
          class="category-div-vid"
          muted="true"
          loop="true"
          autoplay="autoplay"
        >
          <source
            src="https://crossfadefilm.com/iPhone%2012.mp4"
            type="video/mp4"
          />
        </video>

        <Fade className="shoot-name" up duration="3000">
          <h1 style={{ fontFamily: "Lato", letterSpacing: "2px" }}>
            PRODUCT SHOOT
          </h1>
        </Fade>

        <p className="shoot-tag-line">
          Best Product Shoots with Affordable Price
        </p>
        <Fade up duration="3000">
          <div className="two-buttons">
            <a href="/contact">
              <button style={{ color: "white" }} className="shoot-button">
                Lets Shoot
              </button>
            </a>

            <a href="ProductPage">
              <button className="button-existing-inventory">Our Work</button>
            </a>
          </div>
        </Fade>
      </div>
      {/* <div style={{ marginTop: "-7px" }} className="category-div">
        <img
          className="bg-image"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
        ></img>
        <Fade up duration="3000">
          <h1 style={{ left: "35%" }}>ANIMATED VIDEOS</h1>
        </Fade>

        <p className="shoot-tag-line">
          Best Product Shoots with Affordable Price
        </p>
        <Fade up duration="3000">
          <div className="two-buttons">
            <button className="shoot-button">
              <a href="/contact">Lets Shoot</a>
            </button>
            <button className="button-existing-inventory">
              <a href="animated">Our Work</a>
            </button>
          </div>
        </Fade>
      </div> */}
    </>
  );
};

export default Category;

const Wrap = styled.div``;
const ButtonGroup = styled.div`
  top: 50%;
`;
const LeftButton = styled.div``;
const RightButton = styled.div``;
const DownArrow = styled.img``;

// const Wrap = styled.div`
//   width: 100vw;
//   height: 100vh;
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   background-image: ${(props) => `url("/images/${props.bgImage}")`};
// `;

// const ItemText = styled.div`
//   padding-top: 15vh;
//   text-align: center;
// `;

// const ButtonGroup = styled.div`
//   display: flex;
//   margin-bottom: 30px;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const LeftButton = styled.div`
//   height: 40px;
//   background-color: rgba(23, 26, 32, 0.8);
//   width: 250px;
//   color: white;
//   text-transform: uppercase;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   border-radius: 100px;
//   opacity: 0.85;
//   font-size: 12px;
//   margin: 8px;
// `;
// const RightButton = styled(LeftButton)`
//   background-color: white;
//   opacity: 0.65;
//   color: black;
// `;

// const DownArrow = styled.img`
//   cursor: pointer;
//   height: 40px;
//   animation: animateDown infinite 1.5s;
//   overflow-x: hidden;
// `;

// const Button = styled.div``;
