import "./PortfolioPage.css";
import "font-awesome/css/font-awesome.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import CloseIcon from "@mui/icons-material/Close";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
const PortfolioPage = () => {
  let data = [
    {
      id: 100,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/kid/_CFF0011%202.webp&output=webp&maxage=31d",
      category: "kids",
    },

    {
      id: 102,
      imgSrc: "https://crossfadefilm.com/kid/_CFF0106.webp",
      category: "kids",
    },
    {
      id: 103,
      imgSrc: "https://crossfadefilm.com/kid/_CFF0112%202.webp",
      category: "kids",
    },
    {
      id: 104,
      imgSrc: "https://crossfadefilm.com/kid/_CFF0132%202.webp",
      category: "kids",
    },
    {
      id: 105,
      imgSrc: "https://crossfadefilm.com/kid/_CFF0140%202.webp",
      category: "kids",
    },
    {
      id: 106,
      imgSrc: "https://crossfadefilm.com/kid/_CFF0143%203.webp",
      category: "kids",
    },
    {
      id: 107,
      imgSrc: "https://crossfadefilm.com/kid/_CFF0146.webp",
      category: "kids",
    },
    {
      id: 108,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/kid/_CFF0238%203.webp&output=webp&w=1000&maxage=31d",
      category: "kids",
    },
    {
      id: 109,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/kid/_CFF0253%202.webp&output=webp&w=1000&maxage=31d",
      category: "kids",
    },
    {
      id: 110,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/kid/_CFF0276%203.webp&output=webp&w=1000&maxage=31d",
      category: "kids",
    },
    {
      id: 111,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/kid/_CFF0291%203.webp&output=webp&w=1000&maxage=31d",
      category: "kids",
    },
    {
      id: 112,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/_1.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 113,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/_CFF0017.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 114,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/_CFF0020-Edit.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 115,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/_CFF0082.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 116,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/_CFF0094.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 117,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/_CFF0101-2.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 118,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/_CFF0284.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 119,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/CFF00-17.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 120,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/DCP_0051.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 121,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/DCP_0093.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 122,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/product/DCP_0205.webp&output=webp&w=1000&maxage=31d",
      category: "productshoot",
    },
    {
      id: 123,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/DSC02112.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 124,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0180%202.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 125,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0032.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 126,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_1.JPG&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 127,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0281.JPG&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 128,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0327.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 129,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0345.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 130,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0358.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 131,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0406.JPG&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 133,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0414.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 134,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0439.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 135,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0579.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 136,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/_CFF0730.jpg&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 137,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/CFF00-107.JPG&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 138,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/CFF00-141.JPG&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 139,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/events/CFF00-50.JPG&output=webp&w=1000&maxage=31d",
      category: "events",
    },
    {
      id: 140,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/_1.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 141,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/_2.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 142,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/_3.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 143,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/_4.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 144,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/_CFF0128.JPG&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 145,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/CFF-17.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 146,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/CFF-8.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 147,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/DSC01056-HDR.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 148,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/DSC01066-HDR.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 149,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/_CFF0334.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 150,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/DSC01088-HDR%20TV.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 151,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/DSC01112-HDR.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 152,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/corporate/DSC01114-HDR.jpg&output=webp&w=1000&maxage=31d",
      category: "corporate",
    },
    {
      id: 153,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0001-Edit.JPG&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 154,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0005.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 155,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0008%202.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 156,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0008%204.JPG&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 157,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0008.JPG&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 158,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0026.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 159,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0029.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 160,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0039%202.JPG&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 161,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0047%202.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 162,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0223.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 163,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0169.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 164,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0148.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 165,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0081%202.JPG&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 166,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0075.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 167,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0053.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 168,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/_CFF0053-Edit-6.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 169,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/CFF00077.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 170,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/DSC_0095.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 171,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/lifestyle%20and%20fashion/CFF00003.jpg&output=webp&w=1000&maxage=31d",
      category: "lifestyle",
    },
    {
      id: 172,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/adp-0036.JPG&w=1000&output=webp&maxage=31d",
      category: "food",
    },
    {
      id: 173,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/adp-0047.JPG&w=1000&output=webp&maxage=31d",
      category: "food",
    },
    {
      id: 174,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/adp-0055.JPG&w=1000&output=webp&maxage=31d",
      category: "food",
    },
    {
      id: 175,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/adp-0070.JPG&w=1000&output=webp&maxage=31d",
      category: "food",
    },
    {
      id: 176,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/adp-0088.JPG&w=1000&output=webp&maxage=31d",
      category: "food",
    },
    {
      id: 177,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/Biryani10.jpg&w=1000&output=webp&maxage=31d",
      category: "food",
    },
    {
      id: 178,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/Biryani12.jpg&w=1000&output=webp&maxage=31d",
      category: "food",
    },
    {
      id: 179,
      imgSrc:
        "https://images.weserv.nl/?url=https://crossfadefilm.com/Food/Biryani4.jpg&w=1000&output=webp&maxage=31d",
      category: "food",
    },
  ];
  const [model, setModel] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [sortedData, setSortedData] = useState(data);

  const getImg = (imgSrc) => {
    console.log("hi");
    setImgLink(imgSrc);
    setModel(true);
  };
  const filterItem = (cateItem) => {
    const updatedItems = data.filter((item) => {
      return item.category === cateItem;
    });
    setSortedData(updatedItems);
  };
  return (
    <div>
      {/* <h1>Portfolio Page</h1> */}
      <div
        className="photoshoot-img-12 portfolio-head-img"
        style={{
          backgroundImage: `url("https://crossfadefilm.com/photoshoot.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          height: "90vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <img
          style={{
            width: "100%",
            // height: "90vh",

            boxShadow: "0px 0px 10px #000",
          }}
          src="https://images.pexels.com/photos/9149298/pexels-photo-9149298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        /> */}
        <h1
          className="photoshoot-text"
          style={{
            // fontFamily: "Lato sans-serif",
            fontFamily: "Lato",
            fontWeight: "bold",
            color: "black",

            // outline: "1px solid white",
          }}
        >
          PHOTOSHOOT
        </h1>
      </div>

      <h1
        className="my-collection"
        style={{
          textAlign: "center",
          marginTop: "100px",
          fontFamily: "Lato",
        }}
      >
        MY GALLERY
      </h1>
      <div className="menu-tab">
        <button
          className="menu-tab-item"
          onClick={() => {
            filterItem("corporate");
          }}
        >
          CORPORATE
        </button>
        <button
          className="menu-tab-item"
          onClick={() => {
            filterItem("events");
          }}
        >
          EVENTS
        </button>
        <button
          className="menu-tab-item"
          onClick={() => {
            filterItem("productshoot");
          }}
        >
          JEWELLERY AND PRODUCT
        </button>
        <button
          className="menu-tab-item"
          onClick={() => {
            filterItem("kids");
          }}
        >
          KIDS
        </button>
        <button
          className="menu-tab-item"
          onClick={() => {
            filterItem("lifestyle");
          }}
        >
          LIFESTYLE AND FASHION
        </button>

        {/* <button
          className="menu-tab-item"
          onClick={() => {
            setSortedData(data);
          }}
        >
          FOOD
        </button> */}

        <button
          className="menu-tab-item"
          onClick={() => {
            filterItem("food");
          }}
        >
          FOOD
        </button>
      </div>
      <div className={model ? "model open" : "model"}>
        <img loading="lazy" src={imgLink} />
        {/* <FontAwesomeIcon className="cross-symbol" icon="fa-solid fa-xmark" /> */}
        <i
          onClick={() => {
            setModel(false);
          }}
          class="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="portfolio-page">
        {sortedData.map((item) => {
          return (
            <div
              key={item.id}
              className="portfolio-page__item"
              onClick={() => {
                getImg(item.imgSrc);
              }}
            >
              <LazyLoadImage
                loading="lazy"
                style={{ width: "100%" }}
                // key={item.id}
                src={item.imgSrc}
                alt=""
                // loading="lazy"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PortfolioPage;
