import "./Testimonial.css";
import React from "react";
// import video1 from "./video1.mp4";
// import video2 from "./video2.mp4";
// import video3 from "./video3.mp4";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.min.css";
import { Slide } from "react-awesome-reveal";
import { Fade } from "react-awesome-reveal";

const Testimonial = () => {
  const TestData = [
    {
      name: "John Doe",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem  ",
    },
    {
      name: "Kiara Advani",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem ",
    },
    {
      name: "Sam Sanchruz",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem ",
    },
    {
      name: "Udit Katyal",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lorem",
    },
    // {
    //   name: "Johhny",
    //   comment:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    // },
  ];
  return (
    <>
      {/* <div className="Testimonial"> */}
      {/* <div className="about-me-div-1">
        <h1 className="about-me-text">TESTIMONIALS</h1>
      </div> */}
      {/* <div className="testimonial-head">
        <p>Words from previous clients...</p>
      </div> */}
      {/* <div className="testimonial-data-head">
        <Slide duration={3000}>
          {TestData.map((data) => {
            return (
              <div className="testimonial-data">
                <div className="testimonial-name">
                  <p>{data.name}</p>
                </div>
                <div className="testimonial-comment">{data.comment}</div>
                <Fade duration={7000}>
                  <div className="seemore">
                    <a href="#">
                      <button>See More</button>
                    </a>
                  </div>
                </Fade>
              </div>
            );
          })}
        </Slide>
      </div> */}
      {/* <h1 className="testi-head">Testimonials</h1>
      <Carousel interval={null}>
        {TestData.map((item) => {
          return (
            <Carousel.Item key={item.name}>
              <ReactPlayer
                className="video-element"
                url={item.videoElement}
                // width="100%"
                controls={true}
              />
              <Carousel.Caption>
                <h3>{item.name}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
      } */}
      {/*  */}

      {/* <div style={{ marginTop: "-10px" }} class="testimonials-section">
        <input
          type="radio"
          name="slider"
          title="slide1"
          checked="checked"
          class="slider__nav"
        />
        <input type="radio" name="slider" title="slide2" class="slider__nav" />
        <div class="slider__inner">
          <div class="slider__contents">
            <h1 style={{ backgroundColor: "#f8f3ed", padding: "10px 60px" }}>
              Testimonials
            </h1>
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              Bheem is one the star in the field of photography. His skills and
              creativity are par excellence . I collabrated with him when I was
              9 month expecting for a shoot and it went extremely smooth without
              any hassle.
              <span className="slider-other-part">
                He makes his clients very comfortable , comes up with newer
                ideas , his speed of work is simple appreciable. Above all , he
                delivers unmatchable content . I totally recommend him and would
                definately like to work again .
              </span>
            </p>
            <h2 class="slider__caption">Silky | Mommytravelstories</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              Working with Bhim has been an amazing experience. I love his
              professional approach towards his work where he takes care of all
              the details . The videos | shot with him was loved by the brand
              and my audience and feel it's one of the best one on my feed.
              <span className="slider-other-part">
                Hope to do more of such amazing work with him.
              </span>
            </p>
            <h2 class="slider__caption">Best wishes | MITHI</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              It's the long-term relationship we have with Proheat that keeps me
              calling you guys. I trust you, you're quick, and everybody I've
              ever spoken to there are all great people. Our CEO, Bill, talks
              about building relationships. That's exactly what Proheat does,
              and I couldn't be happier.
            </p>
            <h2 class="slider__caption">Chris | C&M Fine Pack</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              You answer my questions, always takes care of problems, and I
              never have a hassle.
            </p>
            <h2 class="slider__caption">Rex | LNP Engineering Plastics</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              Proheat's staff are all so friendly and everybody goes above and
              beyond. Everyone is courteous, everything is quick and you get us
              what we need. I have to shop around for everything and we ALWAYS
              come back to Proheat.
            </p>
            <h2 class="slider__caption">Darlene | Russel Stover</h2>
          </div>
        </div>
      </div> */}
      {/* </div> */}
      <h1
        className="testimonial-margin-top"
        style={{
          position: "relative",
          top: "101px",
          zIndex: "100000",
          backgroundColor: "#f8f3ed",
          padding: "10px 60px",
          textAlign: "center",
          width: "330px",
          alignSelf: "center",
          margin: "auto",
        }}
      >
        Testimonials
      </h1>
      <div class="testimonials-section">
        <input type="radio" name="slider" title="slide1" class="slider__nav" />
        <input type="radio" name="slider" title="slide2" class="slider__nav" />
        <input type="radio" name="slider" title="slide3" class="slider__nav" />
        <input type="radio" name="slider" title="slide4" class="slider__nav" />
        <input type="radio" name="slider" title="slide5" class="slider__nav" />
        <div class="slider__inner">
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              Bhim is one the star in the field of photography. His skills and
              creativity are par excellence .I totally recommend him and would
              definately like to work again.
              {/* <span className="slider-other-part"> */}
              He makes his clients very comfortable , comes up with newer ideas
              , his speed of work is simple appreciable. Above all , he delivers
              unmatchable content .I collabrated with him when I was 9 month
              expecting for a shoot and it went extremely smooth without any
              hassle.
              {/* </span> */}
            </p>

            <h2 class="slider__caption">Silky | Mommytravelstories</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              Working with Bhim has been an amazing experience. I love his
              professional approach towards his work where he takes care of all
              the details .{/* <span className="slider-other-part"> */}
              The videos | shot with him was loved by the brand and my audience
              and feel it's one of the best one on my feed. Hope to do more of
              such amazing work with him.
              {/* </span> */}
            </p>
            <h2 class="slider__caption">Best wishes | MITHI</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              I collaborated with Bhim few months back to shoot a Video. I just
              had an idea that this is the kind of video I want and that is it.
              {/* <span className="slider-other-part"> */}
              After that it was all him. Guiding me all the way through the
              video what to do, how to pose, what is looking good and what is
              not. He is a highly skilled professional who knows his craft like
              a master.
              {/* </span> */}
            </p>
            <h2 class="slider__caption">Sumit Sharma</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              I have been working with Bhim for over 2 years now and it's been a
              wonderful journey. The best part about working with him is the
              ease and comfort he provides.
              {/* <span className="slider-other-part"> */}
              He helps meet crazy deadlines effortlessly. He is excellent with
              kids and my daughter looks forward to shoot with him. His creative
              inputs turn a video around to look visually appealing. Look
              forward to continuing this beautiful association and keep creating
              masterpieces.
              {/* </span> */}
            </p>
            <h2 class="slider__caption">- Farha | Mumfiesta</h2>
          </div>
          <div class="slider__contents">
            <quote>&rdquo;</quote>
            <p class="slider__txt">
              We started shooting few months ago, and there has been no looking
              back because of Bhim’s excellent quality work, sincerity &
              timeliness !{/* <span className="slider-other-part"> */}
              He makes sure the deliver the content right in time but i really
              love about him is his positive attitude & patience.. Shooting with
              a 5 year old is not easy but he does it flawlessly !
              {/* </span> */}
            </p>
            <h2 class="slider__caption">
              - Saru Sharma | diapers_and_lipsticks
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
