import "./Influencer.css";
import Fade from "react-reveal/Fade";
import img6 from "./6.jpg";
const Influencer = () => {
  const videoShown = [
    {
      id: 1,
      videoSrc: "https://www.youtube.com/embed/KzuNbzuGbgg",
    },
    {
      id: 2,
      videoSrc: "https://www.youtube.com/embed/xtbp0lWCuZw",
    },

    {
      id: 3,
      videoSrc: "https://www.youtube.com/embed/lowpo6oX2Uc",
    },
    {
      id: 4,
      videoSrc: "https://www.youtube.com/embed/fzlAKj8YkPc",
    },
    {
      id: 5,
      videoSrc: "https://www.youtube.com/embed/kPWrKW8e1Sc",
    },
    {
      id: 6,
      videoSrc: "https://www.youtube.com/embed/V7KT-B21FQo",
    },
    {
      id: 7,
      videoSrc: "https://www.youtube.com/embed/zyAXf6-WPtY",
    },
    {
      id: 8,
      videoSrc: "https://www.youtube.com/embed/W0YEY_ni5jw",
    },
    {
      id: 9,
      videoSrc: "https://www.youtube.com/embed/5AxiuKGa8R4",
    },
    {
      id: 10,
      videoSrc: "https://www.youtube.com/embed/QM4HZ2XDXCw",
    },
    {
      id: 11,
      videoSrc: "https://www.youtube.com/embed/p5wStTwZRfY",
    },
    {
      id: 12,
      videoSrc: "https://www.youtube.com/embed/dR_6JGtoiIM",
    },
    {
      id: 13,
      videoSrc: "https://www.youtube.com/embed/tIjuk--NDEM",
    },
    {
      id: 14,
      videoSrc: "https://www.youtube.com/embed/vYvrwXTNp4g",
    },
    {
      id: 15,
      videoSrc: "https://www.youtube.com/embed/nPYgH6ARpfo",
    },
    {
      id: 16,
      videoSrc: "https://www.youtube.com/embed/cN2ctSZ2UTM",
    },
    {
      id: 17,
      videoSrc: "https://www.youtube.com/embed/xElXJrHYUKc",
    },
    {
      id: 18,
      videoSrc: "https://www.youtube.com/embed/axFiN--xrF0",
    },
    {
      id: 19,
      videoSrc: "https://www.youtube.com/embed/sSsvPwtRhKE",
    },
  ];
  return (
    <>
      <div>
        <img className="product-image" src={img6} alt="" />
        <Fade up duration="2000">
          <h1 className="images-shoot-lifestyle influencer-specific">
            INFLUENCER SHOOT
          </h1>
        </Fade>
        <a
          style={{
            position: "absolute",
            zIndex: "1",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          href="/contact"
        >
          <Fade duration="4000">
            <button
              className="button-get-in-cat"
              style={{ padding: "10px 20px" }}
            >
              GET IN TOUCH
            </button>
          </Fade>
        </a>
      </div>
      <div className="video-shoot-container-influence">
        {videoShown.map((videoelement) => {
          return (
            <div className="video-shoot-item" key={videoelement.id}>
              {/* <video
                  className="video-shoot-iframe"
                  src={videoelement.videoSrc}
                  controls
                  loop
                  allowFullScreen
                ></video> */}
              <iframe
                src={videoelement.videoSrc}
                style={{ margin: "8px 0" }}
                width="100%"
                height="250px"
                allow="autoplay; encrypted-media"
                allowfullscreen
              />{" "}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Influencer;
