import Slide from "react-reveal/Slide";
import "animate.css";
import "./Video.css";
const Video = () => {
  return (
    <div className="video-container">
      <video
        class="main-reel"
        muted="true"
        loop="true"
        autoplay="true"
        width="100%"
        // autoplay
        controls
      >
        <source
          src="https://crossfadefilm.com/mainreel.webm"
          type="video/mp4"
        />
      </video>
      <Slide bottom duration={3000}>
        <h1 className="video-title">Crossfade Films</h1>
      </Slide>
      {/* <Slide bottom duration={3000}>
        <div className="position">
          <p>ABOUT ME</p>
        </div>
      </Slide> */}
    </div>
  );
};

export default Video;
