import img28 from "../PortfolioPage/img29.jpg";
import img30 from "../PortfolioPage/img15.jpg";
import img35 from "../PortfolioPage/img43.jpg";
import img36 from "../PortfolioPage/img26.jpg";
import img37 from "../PortfolioPage/img49.jpg";
import img38 from "../PortfolioPage/img38.jpg";
import styled from "styled-components";
// import img39 from "../PortfolioPage/img39.jpg";
import "./ImgFooter.css";

const ImgFooter = () => {
  let instaImg = [
    {
      id: 1,
      imgSrc: img35,
      imgLink: "https://www.instagram.com/p/CUAl7qKJbdW/",
    },
    {
      id: 2,
      imgSrc: img30,
      imgLink: "https://www.instagram.com/p/B_H3MKYjhKt/",
    },
    {
      id: 3,
      imgSrc: img28,
      imgLink: "https://www.instagram.com/p/CYeVHdSJXhV/",
    },
    {
      id: 4,
      imgSrc: img36,
      imgLink: "https://www.instagram.com/p/CJ0vu2NhA_n/",
    },
    {
      id: 5,
      imgSrc: img37,
      imgLink: "https://www.instagram.com/p/CKQkBUehNzY/",
    },
    {
      id: 6,
      imgSrc: img38,
      imgLink: "https://www.instagram.com/p/CYwVp4SJkdj/",
    },
  ];
  return (
    <div>
      <FooterImg className="footer-image-container">
        {instaImg.map((footerImg) => {
          return (
            <div className="footer-image__item" key={footerImg.id}>
              <a href={footerImg.imgLink}>
                <img
                  //   onClick={footerImg.imgLink}
                  style={{
                    width: "100%",
                    height: "240px ",
                    objectFit: "cover",
                  }}
                  src={footerImg.imgSrc}
                />
              </a>
            </div>
          );
        })}
        <a href="https://www.instagram.com/crossfade_films/">
          <button className="insta-handle-button">@crossfade_films</button>
        </a>
      </FooterImg>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div style={{}} className="text-center">
              <p>© Copyright 2022. All Rights Reserved by Crossfade Films </p>
              <p style={{ marginTop: "-10px" }}>
                Developed by{" "}
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://rsinfy.com/"
                >
                  RS Infy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterImg = styled.div``;

export default ImgFooter;
