import "./Lifestyle.css";
import Fade from "react-reveal/Fade";
import img11 from "./InfluencerShoot.jpg";
const LifestylePage = () => {
  const videoShown = [
    {
      id: 1,
      videoSrc: "https://www.youtube.com/embed/3-SpT50NUQU",
    },
    {
      id: 2,
      videoSrc: "https://www.youtube.com/embed/jp6fzrZ0tWM",
    },

    {
      id: 3,
      videoSrc: "https://www.youtube.com/embed/7jzStrDVSag",
    },
    {
      id: 4,
      videoSrc: "https://www.youtube.com/embed/-bWIAC0Gfhk",
    },
    {
      id: 5,
      videoSrc: "https://www.youtube.com/embed/db0BYGqtJbg",
    },
    {
      id: 6,
      videoSrc: "https://www.youtube.com/embed/MdE2cL5S0tw",
    },
    {
      id: 7,
      videoSrc: "https://www.youtube.com/embed/-rCjZsoNqW0",
    },
    {
      id: 8,
      videoSrc: "https://www.youtube.com/embed/yq1RK1WHWOk",
    },
    {
      id: 9,
      videoSrc: "https://www.youtube.com/embed/2DQxpFCGVeg",
    },
    {
      id: 10,
      videoSrc: "https://www.youtube.com/embed/y0xY-xjE8MQ",
    },
    {
      id: 11,
      videoSrc: "https://www.youtube.com/embed/KbRF8FI77EM",
    },
    {
      id: 12,
      videoSrc: "https://www.youtube.com/embed/JafB2bBh9iY",
    },
    {
      id: 13,
      videoSrc: "https://www.youtube.com/embed/C0QimR9Cljs",
    },
    {
      id: 14,
      videoSrc: "https://www.youtube.com/embed/KTvu-VkviG0",
    },
    {
      id: 15,
      videoSrc: "https://www.youtube.com/embed/6ollP0vvXnE",
    },
    {
      id: 16,
      videoSrc: "https://www.youtube.com/embed/EH-EnXxBfFg",
    },
    {
      id: 17,
      videoSrc: "https://www.youtube.com/embed/g8xuKNcJT68",
    },
    {
      id: 18,
      videoSrc: "https://www.youtube.com/embed/MfW-i4FHYyM",
    },
    {
      id: 19,
      videoSrc: "https://www.youtube.com/embed/F72oox4BBO8",
    },
  ];
  return (
    <>
      <div className="lifestyle-div">
        <img className="product-image" src={img11} alt="" />
        <Fade up duration="2000">
          <h1 className="images-shoot-lifestyle">LIFESTYLE AND FASHION</h1>
        </Fade>
        <a
          style={{
            position: "absolute",
            zIndex: "1",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          href="/contact"
        >
          <Fade duration="4000">
            <button
              className="button-get-in-cat"
              style={{ padding: "10px 20px" }}
            >
              GET IN TOUCH
            </button>
          </Fade>
        </a>
      </div>
      <div style={{ marginTop: "50px", zIndex: 100 }}>
        <div
          // style={{ columnCount: "2", margin: "auto", width: "70%" }}
          className="video-shoot-container-lifestyle"
        >
          {videoShown.map((videoelement) => {
            return (
              <div className="video-shoot-item" key={videoelement.id}>
                <iframe
                  src={videoelement.videoSrc}
                  // frameborder="0"
                  style={{ margin: "8px 0" }}
                  width="100%"
                  height="250px"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  // title="video"
                />{" "}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default LifestylePage;
