import "./ProductPage.css";
import { Fade } from "react-awesome-reveal";
import img3 from "./3.jpg";
const ProductPage = () => {
  const videoShown = [
    {
      id: 1,
      videoSrc: "https://www.youtube.com/embed/cdE1ZcVzMLU",
    },
    {
      id: 2,
      videoSrc: "https://www.youtube.com/embed/88BOpz0cBps",
    },

    {
      id: 3,
      videoSrc: "https://www.youtube.com/embed/_5-XYycPizE",
    },
    {
      id: 4,
      videoSrc: "https://www.youtube.com/embed/doj7hIFw3sE",
    },
    {
      id: 5,
      videoSrc: "https://www.youtube.com/embed/L4B7mDpaRsc",
    },
  ];
  return (
    <>
      <div>
        <img className="product-image" src={img3} alt="" />
        <Fade up duration={2000}>
          <h1 className="images-shoot-product">PRODUCT SHOOTS</h1>
        </Fade>
        <a
          style={{
            position: "absolute",
            zIndex: "1",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          href="/contact"
        >
          <Fade duration="4000">
            <button
              className="button-get-in-cat"
              style={{ padding: "10px 20px" }}
            >
              GET IN TOUCH
            </button>
          </Fade>
        </a>
      </div>
      <div style={{ marginTop: "20px", zIndex: 100 }}>
        <div
          style={{ columnCount: "2", margin: "auto", width: "70%" }}
          className="video-shoot-container"
        >
          {videoShown.map((videoelement) => {
            return (
              <div className="video-shoot-item" key={videoelement.id}>
                {/* <video
                  className="video-shoot-iframe"
                  src={videoelement.videoSrc}
                  controls
                  loop
                  allowFullScreen
                ></video> */}
                <iframe
                  src={videoelement.videoSrc}
                  style={{ margin: "8px 0" }}
                  width="100%"
                  height="250px"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                />{" "}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default ProductPage;
